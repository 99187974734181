import React, { createContext, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { Loader } from '@asg/core';
import generatedLinks from 'configs/routes-static';
import { useGetProfileQuery } from 'services/user-administration/api';
import type { Profile } from 'types/users';

const INIT: Profile = {
  userId: 0,
};

/**
 * @deprecated don't use this context. use useProfile hook instead of that
 */
export const ProfileContext = createContext<[Profile]>([INIT]);

type AuthProviderProps = {
  children: React.ReactNode;
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { data, isLoading: loading } = useGetProfileQuery();

  const history = useHistory();
  const hasOneTimePassword = data?.permissions?.includes('USER_BASIC_CREDENTIAL_CHANGE_OWN_PASSWORD_PERMISSION');

  useEffect(() => {
    if (hasOneTimePassword && history.location.pathname !== generatedLinks.auth.login.url) {
      history.push(generatedLinks.auth.setPassword.url);
    }
  }, [history, hasOneTimePassword]);

  return (
    <ProfileContext.Provider value={[data!]}>
      {loading ? (
        <Loader
          pastDelay
          className="h-100"
        />
      ) : (
        children
      )}
    </ProfileContext.Provider>
  );
};

export default AuthProvider;
