/**
 * Периодичность оплаты премии в карточке (Cooloff, в табе Полис), ПДП, Полис
 */
export const paymentPeriods: Record<string, string> = {
  WHOLE_SUM: 'Единовременно',
  MONTHLY: 'Ежемесячно',
  QUARTERLY: 'Ежеквартально',
  SEMIANNUALLY: 'Раз в пол года',
  ANNUALLY: 'Ежегодно',
  NO_DATA: '',
};

export const registryStatuses: {
  [key: string]: string;
} = {
  NEW: 'Новый',
  IN_PROCESS: 'Рассмотрение',
  PROCESSED: 'Получен',
  PROCESSED_HAS_DEFECT: 'Обработан с ошибками',
  RETURNED: 'Возвращен',
  PAID: 'Оплачен',
  REJECTED: 'Отклонен',
  NONE: 'нет данных',
};

export const agentFormValue: Record<string, string> = {
  OOO: '"OOO"',
  OAO: '"OAO"',
  PAO: '"ПАО"',
  ZAO: '"ЗАО"',
  AO: '"AO"',
  NO_DATA: '',
};

export const shortLabelPeriods: Record<string, string> = {
  MONTH: 'мес(лет)',
  YEAR: 'лет',
  WEEK: 'нед.',
  DAY: 'дн.',
  NO_DATA: '',
};

export const policyStatuses: Record<string, any> = {
  DRAFT: 'Черновик',
  ACTIVE: 'Действует',
  CLOSED: 'Истек срок действия',
  TERMINATED: 'Расторгнут',
  CANCELED: 'Отменен',
  CREATED: 'Создан',
};

export const policySubStatuses: Record<string, any> = {
  PAID: 'Оплачен',
  COOLOFF: 'Cool-off',
  BY_COURT: 'Требование суда',
  EXPIRED: 'Истек срок действия',
  INSURANCE_CASE: 'Страховое событие',
  CLIENT_REQUEST: 'Запрос клиента',
  PDP: 'ПДП',
  AGENT_REQUEST: 'Запрос агента',
  NONCONFORMITY: 'Несоответствие требованиям страхования',
  USER_MISTAKE: 'Ошибка пользователя',
  NOT_PAID: 'Не оплачен',
};

export const letterReportStatus: Record<string, string> = {
  CONFIRMED: 'Подтвержден',
  IN_PROCESS: 'В работе',
};

export const letterReportAddStatus: Record<string, string> = {
  ERRORS: 'Ошибки',
  PAYOFF_DENIED: 'Отказ в выплате',
  ACCEPTED_CASH: 'Принято наличные',
  SENT_LETTER_WITH_ERRORS: 'Передано на отправку письма_ошибки',
  SENT_LETTER_WITH_DENIAL: 'Передано на отправку письма_отказ',
  SENT_LETTER_WITH_CASH_PAYOFF: 'Передано на отправку письма_наличные',
};

export const terminationSubTypeOptions: Record<string, string> = {
  CANCEL: 'Cooloff ДО вступления в силу',
  CLF: 'Cooloff ПОСЛЕ вступления в силу',
  PART: 'Частичный Cool-off',
  PDP: 'ПДП',
};

export const insuredStructureTypes: Record<string, string> = {
  COLLECTIVE: 'Коллективный',
  INDIVIDUAL: 'Индивидуальный',
};

export const statusAct: Record<string, string> = {
  APPROVED: 'Согласован',
  CANCELED: 'Аннулирован',
  COMPLETED: 'Сформирован',
  CREATED: 'Формируется',
  IN_PROGRESS: 'Формируется',
  PAID: 'Оплачен',
  SENT_TO_PAYOFF: 'Отправлен на выплату',
  CANCELLATION: 'Аннулирование',
  PAYOFF_REJECTED: 'Возврат РНВ',
};

export const taskActionText: Record<string, any> = {
  CANCEL_BY_POLICYHOLDER: 'Закрытие заявления по причине отказа Страхователя от расторжения',
  DUPLICATE: 'Перевод заявления в Дубль',
  MISTAKE: 'Закрытие заявления по причине ошибочной регистрации',
  UPDATE: 'Обновление данных',
  REVOKE: 'Отзыв РНВ',
  RESET_ACT: 'Отвязка от действующего Акта',
  UPDATE_COMMENT: 'Обновление комментария',
  REASSIGN_USER: 'Переназначение заявления Cool-off на другого исполнителя',
  SENT_LETTER_THROUGH_ANOTHER_DEPARTMENT: 'Перевод заявления в статус конечный статус',
  NONE: 'Обновление данных',
};

export const contractConclusion: Record<string, any> = {
  REGISTRY: 'В реестре',
  PRODUCT: 'В продукте',
  NO_DATA: ' ',
};

export const genderValue: Record<string, string> = {
  MALE: 'муж.',
  FEMALE: 'жен.',
  NO_DATA: '-',
};

export const terminationRawStatuses = {
  DRAFT: 'DRAFT',
  IN_PROCESS: 'IN_PROCESS',
  RETURNED_PAYOFF: 'RETURNED_PAYOFF',
};

export const deliveryFormatDict: Record<string, string> = {
  ORIGINAL: 'Оригинал',
  COPY: 'Копия',
  COPY_TO_WORK: 'Копия в работу',
  TELEGRAM: 'Телеграмма',
};

export const terminationAddStatus: Record<string, string> = {
  SENT_TO_PAYOFF: 'Передано на выплату',
  PAID: 'Выплачено',
};

export const formStatement: Record<string, any> = {
  TEMPLATE: 'Шаблонная',
  HANDWRITTEN: 'Рукописная',
};
export const formSupply: Record<string, any> = {
  ORIGINAL: 'Оригинал',
  COPY: 'Копия',
  COPY_TO_WORK: 'Копия в работу',
  TELEGRAM: 'Телеграмма',
};

export const typePayment: Record<string, any> = {
  NOT_CASH: 'Безналичные',
  CASH: 'Наличные',
  POSTAL_TRANSFER: 'Почтовый перевод',
};

export const coolOffStatus: Record<string, string> = {
  ACCEPTED_NOT_CASH: 'Принято б/н',
  ACCEPTED_CASH: 'Принято наличные',
  ACCEPTED_POSTAL_TRANSFER: 'Принято почтовый перевод',
  NEW_REG_CARD: 'Новое СЭД',
  REGISTERED: 'Зарегистрировано',
  REGISTERED_BY_MISTAKE: 'Ошибочная регистрация',
  DUPLICATE: 'Дубль',
  CANCELED_BY_POLICYHOLDER: 'Отказ Страхователя от расторжения',
  PAID_BY_BANK: 'Возврат на стороне банка',
  ERRORS: 'Ошибки',
  PAYOFF_DENIED: 'Отказ в выплате',
  REQUEST_SENT_TO_DEPARTMENT: 'Отправлен запрос в подразделение',
  REQUEST_SENT_TO_BANK: 'Отправлен запрос в банк',
  SENT_TO_PAYOFF: 'Передано на выплату',
  PAID: 'Выплачено',
  RETURNED_PAYOFF: 'Возврат из ФД',
  SENT_LETTER_WITH_ERRORS: 'Передано на отправку письма_ошибки',
  SENT_LETTER_WITH_CASH_PAYOFF: 'Передано на отправку письма_наличные',
  SENT_LETTER_WITH_DENIAL: 'Передано на отправку письма_отказ',
  NOTIFICATION_ABOUT_CASH_PAYMENT: 'Уведомление клиента о выплате наличными',
  NOTIFICATION_ABOUT_NOT_CASH_PAYMENT: 'Уведомление клиента о выплате безналичными',
  NOTIFICATION_ABOUT_POSTAL_TRANSFER_PAYMENT: 'Уведомление клиента о выплате почтовым переводом',
  TO_BANK_CANCELLATION: 'На аннулирование в банк',
  SENT_TO_BANK_FOR_CANCELLATION: 'Отправлено на аннулирование в банк',
  PAYOFF_DENIED_HAS_NO_VIOLATIONS: 'Отказ в выплате нарушений нет',
  PAYOFF_DENIED_LOSS_PAID: 'Отказ в выплате убыток выплачен',
  APPEAL_NOT_ON_TIME_DENIED: 'Отказ обращение в СК не в срок',
  NOTIFICATION_ABOUT_LOSS_IN_PROCESS: 'Уведомление клиента убыток',
  SENT_LETTER_WITH_LOSS: 'Передано на отправку письма убыток',
};

export const applicationStatus = coolOffStatus;

export const TypeReference: Record<string, string> = {
  HAVE_TAX_DEDUCTION: 'О получении налогового вычета',
  HAVE_NOT_TAX_DEDUCTION: 'О неполучении налогового вычета',
  DENIAL_OF_TAX_CERTIFICATE: 'Об отказе в выдаче справки',
  NONE: '',
};

export const payoffCalculateType: Record<string, string> = {
  FULL_PREMIUM: 'Полная премия',
  PRO_RATA: 'Выплата с учетом Про Рата',
  NO_DATA: '',
};

export const pdpTypeReportExel: Record<string, string> = {
  LOAN_REPAYMENT_DATE: 'ДПК',
  CLIENT_APPLICATION_DATE: 'ДПЗ',
  NO_DATA: '',
};

export const isValue = {
  true: 'Да',
  false: 'Нет',
} as const;

export const pdpStatus: Record<string, any> = {
  ACCEPTED_NOT_CASH: 'Принято б/н',
  ACCEPTED_CASH: 'Принято наличные',
  ACCEPTED_POSTAL_TRANSFER: 'Принято почтовый перевод',
  NEW_REG_CARD: 'Новое СЭД',
  REGISTERED: 'Зарегистрировано',
  REGISTERED_BY_MISTAKE: 'Ошибочная регистрация',
  DUPLICATE: 'Дубль',
  CANCELED_BY_POLICYHOLDER: 'Отказ Страхователя от расторжения',
  PAID_BY_BANK: 'Возврат на стороне банка',
  ERRORS: 'Ошибки',
  PAYOFF_DENIED: 'Отказ в выплате',
  REQUEST_SENT_TO_DEPARTMENT: 'Отправлен запрос в подразделение',
  REQUEST_SENT_TO_BANK: 'Отправлен запрос в банк',
  SENT_TO_PAYOFF: 'Передано на выплату',
  PAID: 'Выплачено',
  RETURNED_PAYOFF: 'Возврат из ФД',
  SENT_LETTER_WITH_ERRORS: 'Передано на отправку письма ошибки',
  SENT_LETTER_WITH_CASH_PAYOFF: 'Передано на отправку письма наличные',
  SENT_LETTER_WITH_DENIAL: 'Передано на отправку письма отказ',
  NOTIFICATION_ABOUT_CASH_PAYMENT: 'Уведомление клиента о выплате наличными',
  NOTIFICATION_ABOUT_NOT_CASH_PAYMENT: 'Уведомление клиента о выплате безналичными',
  NOTIFICATION_ABOUT_POSTAL_TRANSFER_PAYMENT: 'Уведомление клиента о выплате почтовым переводом',
  TO_BANK_CANCELLATION: 'На аннулирование в банк',
  SENT_TO_BANK_FOR_CANCELLATION: 'Отправлено на аннулирование в банк',
  PAYOFF_DENIED_HAS_NO_VIOLATIONS: 'Отказ в выплате нарушений нет',
  PAYOFF_DENIED_INSURANCE_CONDITIONS: 'Отказ в выплате (условия страхования)',
  PAYOFF_DENIED_LOSS_PAID: 'Отказ в выплате (убыток выплачен)',
  APPEAL_NOT_ON_TIME_DENIED: 'Отказ обращение в СК не в срок',
  NOTIFICATION_ABOUT_LOSS_IN_PROCESS: 'Уведомление клиента убыток',
  SENT_LETTER_WITH_LOSS: 'Передано на отправку письма убыток',
  CREDIT_REPAYMENT_NOT_ON_TIME_DENIED: 'Отказ погашение кредита не в срок',
};

export const taskActionTextPdp: Record<string, any> = {
  ...taskActionText,
  REASSIGN_USER: 'Переназначение заявления ПДП на другого исполнителя',
};

export const pdpAddStatusDict: Record<string, string> = {
  NEW_REG_CARD: 'Новое СЭД',
  REGISTERED: 'Зарегистрировано',
  REGISTERED_BY_MISTAKE: 'Ошибочная регистрация',
  DUPLICATE: 'Дубль',
  CANCELED_BY_POLICYHOLDER: 'Отказ Страхователя от расторжения',
  ERRORS: 'Ошибки',
  PAYOFF_DENIED: 'Отказ',
  PAYOFF_DENIED_INSURANCE_CONDITIONS: 'Отказ в выплате (условия страхования)',
  PAYOFF_DENIED_LOSS_PAID: 'Отказ в выплате (убыток выплачен)',
  CREDIT_REPAYMENT_NOT_ON_TIME_DENIED: 'Отказ погашение кредита не в срок',
  APPEAL_NOT_ON_TIME_DENIED: 'Отказ обращение в СК не в срок',
  NOTIFICATION_ABOUT_LOSS_IN_PROCESS: 'Уведомление клиента (убыток заявлен)',
  NOTIFICATION_ABOUT_CASH_PAYMENT: 'Уведомление клиента о выплате наличными',
  REQUEST_SENT_TO_DEPARTMENT: 'Отправлен запрос в подразделение',
  REQUEST_SENT_TO_BANK: 'Отправлен запрос в банк',
  ACCEPTED_NOT_CASH: 'Принято б/н',
  ACCEPTED_CASH: 'Принято наличные',
  ACCEPTED_POSTAL_TRANSFER: 'Принято почтовый перевод',
  PAID_BY_BANK: 'Возврат на стороне банка',
  SENT_TO_PAYOFF: 'Передано на выплату',
  PAID: 'Выплачено',
  SENT_LETTER_WITH_ERRORS: 'Передано на отправку письма ошибки',
  SENT_LETTER_WITH_CASH_PAYOFF: 'Передано на отправку письма наличные',
  SENT_LETTER_WITH_LOSS: 'Передано на отправку письма убыток',
  SENT_LETTER_WITH_DENIAL: 'Передано на отправку письма отказ',
  RETURNED_PAYOFF: 'Возврат из ФД',
};
