import { api, rtk } from 'shared/api';

import { AuthResponse } from './types';
import { getHashPassword } from './utils';

export const authApi = rtk.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<AuthResponse, { captchaToken?: string; login: string; password: string }>({
      query: ({ password, login, captchaToken }) => ({
        url: api.auth.login,
        method: 'POST',
        credentials: 'include',
        body: {
          login,
          captchaToken,
          passwordHash: getHashPassword(password),
        },
      }),
      invalidatesTags: ['profile'],
    }),
    logout: builder.mutation<AuthResponse, void>({
      query: () => ({
        url: api.auth.logout,
        method: 'POST',
        credentials: 'include',
      }),
    }),
    resetPassword: builder.mutation<void, { userId: string | number; confirmPassword: string; password: string }>({
      query: ({ password, confirmPassword, userId }) => ({
        url: api.userAdministration.setPassword.replace(':user-id', userId?.toString()),
        method: 'POST',
        credentials: 'include',
        body: {
          passwordHash: getHashPassword(password),
          passwordHashConfirm: getHashPassword(confirmPassword),
        },
      }),
    }),
  }),
});

export const { useLoginMutation, useResetPasswordMutation, useLogoutMutation } = authApi;
