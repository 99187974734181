import { useHistory, useLocation } from 'react-router-dom';

import { useFetch, CachePolicies } from '@asg/core';
import generatedLinks from 'configs/routes-static';
import qs from 'qs';
import { useLoginMutation, useResetPasswordMutation } from 'services/auth';
import { useProfile } from 'slices';
import type { ErrorResponse } from 'types/general';

import getApiHost, { api } from '../get-api-host';

type UseRefreshTokenResponse = {
  refresh: () => Promise<any | ErrorResponse>;
  loading: boolean;
};

export const useLogin = () => {
  const [post, { data, isLoading, error }] = useLoginMutation();

  const history = useHistory();
  const location = useLocation();

  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  const loginPost = (password: string, login: string, captchaToken?: string) =>
    post({
      login,
      password,
      captchaToken,
    }).then((response) => {
      if (!response?.error) {
        history.push((search.redirectUrl as string) || generatedLinks.cabinet.url);
      }
      return response;
    });

  return {
    loading: isLoading,
    login: loginPost,
    data,
    error,
  };
};

export const useRefreshToken = (): UseRefreshTokenResponse => {
  const { post, loading } = useFetch(`${getApiHost()}/${api.auth.refreshToken}`, {
    credentials: 'include',
    cachePolicy: CachePolicies.NO_CACHE,
    showError: false,
  });

  return {
    loading,
    refresh: post,
  };
};

export const useSetPassword = () => {
  const profile = useProfile();
  const [reset, { isLoading: loading }] = useResetPasswordMutation();

  const history = useHistory();

  const setPasswordPost = (password: string, confirmationPassword: string) => {
    // if (!password || !confirmationPassword) {
    //   return reject({
    //     message: 'Введите пароль',
    //   });
    // }
    // if (password !== confirmationPassword) {
    //   return reject({
    //     message: 'Пароли должны совпадать',
    //   });
    // }
    if (password === confirmationPassword) {
      return reset({
        password: password,
        confirmPassword: password,
        userId: profile.userId,
      }).then((response) => {
        if (!response.error) {
          history.push(generatedLinks.auth.login.url);
        }
        return response;
      });
    }
    return Promise.resolve();
  };

  return {
    loading,
    setPassword: setPasswordPost,
  };
};
