import { generatePath } from 'react-router-dom';

import { api, rtk } from 'shared/api';
import { Profile } from 'types/users';

export const userAdministrationApi = rtk.injectEndpoints({
  endpoints: (build) => ({
    setPassword: build.mutation({
      query: () => ({
        url: api.userAdministration.setPassword,
      }),
    }),
    resetUserPassword: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: generatePath(api.userAdministration.resetUserPassword, { id }),
      }),
    }),
    getProfile: build.query<Profile, void>({
      query: () => ({
        url: api.userAdministration.profile,
      }),
      providesTags: ['profile'],
    }),
    getAgent: build.query<void, { id: number }>({
      query: ({ id }) => ({
        url: `${api.userAdministration.agentList}/${id}`,
      }),
    }),
    saveAgent: build.mutation<void, { id?: number }>({
      query: ({ id }) => ({
        url: `${api.userAdministration.agentList}/${id || ''}`,
        method: id ? 'PUT' : 'POST',
      }),
    }),
    getUser: build.query<void, { id: number }>({
      query: ({ id }) => ({
        url: `${api.userAdministration.userList}/${id}`,
      }),
    }),
    saveUser: build.mutation<void, { id?: number }>({
      query: ({ id }) => ({
        url: `${api.userAdministration.userList}/${id || ''}`,
        method: id ? 'PUT' : 'POST',
      }),
    }),
    getRole: build.query<void, { id: number }>({
      query: ({ id }) => ({
        url: `${api.userAdministration.roleList}/${id}`,
      }),
    }),
    saveRole: build.mutation<void, { id?: number }>({
      query: ({ id }) => ({
        url: `${api.userAdministration.roleList}/${id || ''}`,
        method: id ? 'PUT' : 'POST',
      }),
    }),
    getDivision: build.query<void, { id: number }>({
      query: ({ id }) => ({
        url: `${api.userAdministration.divisionList}/${id}`,
      }),
    }),
    saveDivision: build.mutation<void, { id?: number }>({
      query: ({ id }) => ({
        url: `${api.userAdministration.divisionList}/${id || ''}`,
        method: id ? 'PUT' : 'POST',
      }),
    }),
  }),
});

export const { useGetProfileQuery } = userAdministrationApi;
